import * as React from "react";
import styles from "./App.module.scss";
import SkidataLogo from "./assets/skidata-logo.png";
import Home from "./components/pages/organism/home/home";
import { connect } from "react-redux";
import CurrentRequests from "./components/pages/organism/currentRequests/currentRequests";
import Help from "./components/pages/organism/help/help";
import Request from "./components/pages/organism/request/index";
import PopOut from "./components/pages/organism/popOut/popOut";
import { Login, Providers, ProviderState } from "@microsoft/mgt-react";
import { useEffect, useState } from "react";
import { setAdminPanel, setHome } from "./redux/actions/currentPage.actions";
import ContactList from "./components/pages/organism/ContactList/ContactList";
import { setUser } from "./redux/actions/user.actions";
import TicketingPartners from "./components/pages/organism/ticketingPartners/ticketingPartners";
import AdminPanel from "./components/pages/organism/adminPanel/adminPanel";
import RequestView from "./components/pages/organism/requestView/requestView";
import { setEmail } from "./redux/actions/email.actions";
import { FaCog } from "react-icons/fa";

function App(props: any) {
  const [isSignedIn, setIsSignedIn] = useState<Boolean>(false);
  const [maintenanceWork, setMaintenanceWorks] = useState(false);

  useEffect(() => {
    const updateState = () => {
      const provider = Providers.globalProvider;
      setIsSignedIn(provider && provider.state === ProviderState.SignedIn);
    };

    Providers.onProviderUpdated(updateState);
    updateState();

    return () => {
      Providers.removeProviderUpdatedListener(updateState);
    };
  }, []);

  useEffect(() => {
    Providers.globalProvider.graph.client
      .api("me")
      .get()
      .then((gotMe) => {
        props.setUser(gotMe.displayName);
        props.setEmail(gotMe.mail);
        console.log(gotMe);
        console.log(gotMe.mail);
      });
  }, [isSignedIn]);

  var adminList = [
    "Marcin Bednarz - SKIDATA",
    "Andrew Swann - SKIDATA",
    "Albert Kiefel - SKIDATA",
    "Mauricio Bastos - SKIDATA",
    "Matthias Herzog - SKIDATA",
    "Florian Schnöll - SKIDATA",
    "Princely Kolle Epie - SKIDATA",
    "Yan Delomez - SKIDATA"
  ];

  return (
    <>
      <PopOut />
      <div className={styles.appContainer}>
        <header>
          <div className={styles.logoLongContainer}>
            <div className={styles.logoContainer}>
              <img alt="" src={SkidataLogo} className={styles.welcomeImage} />
              <h1>CUSTOMIZED SOLUTIONS</h1>
            </div>
            <h2>Request Point</h2>
          </div>

          <div className={styles.searchAndSignContainer}>
            {adminList.includes(props.user) && (
              <p
                className={styles.adminButton}
                onClick={() => props.setAdminPanel()}
              >
                ADMIN
              </p>
            )}

            <input placeholder="Search" />
            <Login />
          </div>
        </header>

        <>
          {props.page === "home" && <Home />}
          {props.page === "request" && <Request />}
          {props.page === "ticketing" && <TicketingPartners />}
          {props.page === "currentRequests" && <CurrentRequests />}
          {props.page === "contactList" && <ContactList />}
          {props.page === "help" && <Help />}
          {props.page === "adminPanel" && <AdminPanel />}
          {props.page === "requestView" && <RequestView />}
        </>
        {isSignedIn && !maintenanceWork ? (
          props.user !== "unknown" ? (
            <></>
          ) : (
            <p>Loading...</p>
          )
        ) : (
          <>
            {maintenanceWork ? (
              <div className={styles.app__maintenance__container}>
                <div className={styles.app__maintenance__windowBox}>
                  <h4>We are under maintenance </h4>
                  <div className={styles.app__maintenance__icon}>
                    <FaCog />
                  </div>
                  <p>
                    Our Request Porlat is temporarily down for maintenance until
                    <span>May 30, 2023, 14:00 (CET).</span> We apologize for any
                    inconvenience and appreciate your understanding. In case of
                    urgent request please contact us by email: ccs@skidata.com
                  </p>
                </div>
              </div>
            ) : (
              <>
                <p>To continue please Sign in with Skidata MS account</p>
                <Login />
              </>
            )}
          </>
        )}

        <div className={styles.environmentMessage}>
          <p>SKIDATA GCS 2023 v1.1.28 beta</p>
        </div>
      </div>
    </>
  );
}

const mapStateToProps: any = (state: any) => {
  return {
    page: state.page,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHome: () => dispatch(setHome()),
    setUser: (userName: string) => dispatch(setUser(userName)),
    setEmail: (email: string) => dispatch(setEmail(email)),
    setAdminPanel: () => dispatch(setAdminPanel()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
